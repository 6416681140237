<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <b-col class="pl-0">
            <span class="header-tablepage"> Campaign Detail</span>ㅤ<span
              class="header-tablepage"
              >({{
                statusCampaign == 2
                  ? "Reject"
                  : statusCampaign == 1
                  ? "Approve"
                  : "Pending"
              }})</span
            >
          </b-col>
        </div>
        <div class="title-tabs mt-3">Receipt Detail</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row v-for="(field, index) in detail" :key="index">
              <b-col md="6">
                <div v-if="field.field_type_id == 1" class="mb-3">
                  <FieldTextInput
                    :field="field"
                    :statusCampaign="statusCampaign"
                  />
                </div>

                <div v-else-if="field.field_type_id == 2" class="mb-3">
                  <FieldCheckbox
                    :field="field"
                    :statusCampaign="statusCampaign"
                    :index="index"
                  />
                </div>
                <div v-else-if="field.field_type_id == 3" class="mb-3">
                  <FieldRadio
                    :field="field"
                    :statusCampaign="statusCampaign"
                    :index="index"
                  />
                </div>
                <div v-else-if="field.field_type_id == 4" class="mb-3">
                  <FieldDropdown
                    :field="field"
                    :statusCampaign="statusCampaign"
                  />
                </div>
                <div v-else-if="field.field_type_id == 5" class="mb-3">
                  <FieldDateTime
                    :field="field"
                    :statusCampaign="statusCampaign"
                  />
                </div>
                <div v-else-if="field.field_type_id == 6" class="mb-3">
                  <FieldBranch
                    :field="field"
                    :statusCampaign="statusCampaign"
                  />
                </div>
                <div v-else-if="field.field_type_id == 7" class="mb-3">
                  <FieldDate :field="field" :statusCampaign="statusCampaign" />
                </div>
                <div v-else-if="field.field_type_id == 8">
                  <label
                    >{{ field.name }}
                    <span class="text-danger" v-if="field.required">*</span>
                  </label>

                  <UploadImage
                    :disabled="
                      statusCampaign != 0 && field.field_profile_type_id != 0
                    "
                    :indexData="index"
                    @updateImageList="updateImageList"
                    :dataList="field.value"
                    @deleteImagesList="deleteImgList"
                  />
                  <a :href="field.value" target="_blank">
                    <span>
                      {{ field.name }}
                      <b-icon icon="search" variant="grey"></b-icon>
                    </span>
                  </a>
                </div>
                <div v-else-if="field.field_type_id == 12">
                  <FieldTextAreaInput
                    :field="field"
                    :statusCampaign="statusCampaign"
                  />
                </div>
                <div v-else-if="field.field_type_id == 15">
                  <InputSelect
                    :title="field.name"
                    :name="field.name"
                    :options="field.field_choices"
                    v-model="field.value"
                    valueField="name"
                    textField="name"
                    isRequired
                    @onDataChange="
                      (val) =>
                        changeAddress(val, field, field.field_profile_type_id)
                    "
                    :disabled="field.field_choices.length == 0"
                  >
                    <!-- isRequired -->
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >Please Select
                      </b-form-select-option>
                    </template>
                  </InputSelect>
                </div>
              </b-col>
              <b-col md="6" class="d-md-down-none"> </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="title-tabs mt-3">For Giving Additional Points</div>
        <b-container class="no-gutters bg-white">
          <div class="pt-3">
            <b-row>
              <b-col sm="6">
                <InputText
                  textFloat="Points Earned"
                  placeholder="0"
                  :disabled="statusCampaign != 0"
                  v-model="form.point"
                  inputmode="numeric"
                  type="number"
                  name="numberScore"
                /> </b-col
              ><b-col sm="6"></b-col>
              <b-col sm="6">
                <InputTextArea
                  textFloat="Note"
                  type="text"
                  :disabled="statusCampaign != 0"
                  v-model="form.note"
                  placeholder="Note"
                  rows="3"
                  name="note"
                /> </b-col
              ><b-col sm="6"></b-col>
            </b-row>
          </div>
        </b-container>
      </b-form>
      <div class="footer-action">
        <b-col>
          <router-link :to="`/form/detail/${campaignId}?edit=1`">
            <b-button class="btn-cancel btn-width">Back</b-button>
          </router-link>
        </b-col>
        <b-col class="text-right">
          <b-button
            class="main-color btn-save mr-2"
            @click="handleReject()"
            :disabled="statusCampaign == 1"
            >{{ statusCampaign == 2 ? "Save" : "Reject" }}
          </b-button>
          <b-button
            class="main-color btn-save"
            @click="submitCampaign(1)"
            :disabled="statusCampaign == 2"
          >
            {{ statusCampaign == 1 ? "Save" : "Approve" }}
          </b-button>
        </b-col>
      </div>
    </div>
    <b-modal
      v-model="modalMessageShow"
      title="Product List"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      header-class="bg-purple1"
    >
      <template #modal-header>
        <div class="w-100">
          <strong class="title-modal white-color">Respond/Reject</strong>
          <b-icon
            icon="x-circle-fill"
            class="float-right pointer white-color"
            @click="modalMessageShow = false"
          ></b-icon>
        </div>
      </template>
      <div>
        <label>Additional Reasons (if any)</label>
        <InputTextArea
          textFloat=""
          type="text"
          v-model="form.message"
          class="mt-0 custom-input"
          placeholder="Provide Additional Reasons"
          rows="3"
          name="message"
        />
        <b-row>
          <b-col sm="6">
            <b-button class="cancel-btn" @click="cancelMessageModel">
              Cancel
            </b-button>
          </b-col>
          <b-col sm="6" class="d-flex justify-content-end">
            <b-button class="approve-btn" @click="submitMessageModel">
              Save
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import FieldTextInput from "./component/FieldTextInput.vue";
import FieldCheckbox from "./component/FieldCheckbox.vue";
import FieldRadio from "./component/FieldRadio.vue";
import FieldDropdown from "./component/FieldDropdown.vue";
import FieldDateTime from "./component/FieldDateTime.vue";
import FieldBranch from "./component/FieldBranch.vue";
import FieldDate from "./component/FieldDate.vue";
import FieldTextAreaInput from "./component/FieldTextAreaInput.vue";
import UploadImage from "./component/UploadImage.vue";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    InputText,
    InputTextArea,
    FieldTextInput,
    FieldCheckbox,
    FieldRadio,
    FieldDropdown,
    FieldDateTime,
    FieldBranch,
    FieldDate,
    UploadImage,
    FieldTextAreaInput,
    ModalAlertError,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      fields: [],
      detail: [],
      detailOriginal: [],
      branchList: [],
      deleteImageList: [],
      campaignId: this.$route.query.formID,
      statusCampaign: null,
      filter: {
        formId: this.$route.query.campaignID,
        id: parseInt(this.$route.params.id),
      },
      form: {
        user_id: 0,
        point: 0,
        note: "",
        telephone: "",
        message: "",
      },
      checkboxIndexList: [],
      modalMessage: "",
      modalMessageShow: false,
      submitTriger: false,
      isLoading: true,
    };
  },
  watch: {
    isLoading: {
      handler(val) {
        if (!this.isLoading) {
          setTimeout(() => {
            this.triggerCheckbox();
          }, 100);
        }
      },
    },
    detail: {
      handler(val) {
        if (this.submitTriger) {
          this.customValidation();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getFormDetail();

    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 5000);
  },
  destroyed() {},
  methods: {
    handleReject() {
      return this.statusCampaign == 2
        ? this.submitCampaign(0)
        : this.openMessageModal();
    },
    async getFormDetail() {
      let haveSelectBranch = false;
      this.isLoading = true;
      await this.$store.dispatch("getCampaignUserDetail", this.filter);
      let data = this.$store.state.form.campaignDetail;
      if (data.result == 1) {
        this.form.user_id = data.detail.user_id;
        this.form.note = data.detail.note;
        this.form.point = data.detail.point;
        this.statusCampaign = data.detail.status;
        this.fields = data.detail.fields;
        let itemDetail = data.detail.user_result;

        itemDetail.push({
          answer_id: 14209,
          field_id: 979,
          field_profile_type_id: 19,
          field_type_id: 9,
          is_update: 0,
          name: "สถานะ",
          value: 0,
        });

        for (const n of itemDetail) {
          n.value = itemDetail
            .filter((el) => el.field_id == n.field_id)
            .map((els) => els.value)
            .join(",");
        }
        itemDetail = itemDetail.filter(
          (obj, index) =>
            itemDetail.findIndex((item) => item.field_id === obj.field_id) ===
            index
        );

        this.detailOriginal = JSON.stringify(itemDetail);
        this.fields.forEach((e) => {
          itemDetail.forEach(async (d) => {
            if (e.field_id == d.field_id) {
              d.required = e.required;
              d.field_choices = e.field_choices;
              d.field_conditions = e.field_conditions;
              d.field_dependent = e.field_dependent;

              this.detail.push(d);
              if (d.field_type_id == 6) {
                haveSelectBranch = true;
                await this.getBranchList(d, e.branch_group_id);
              }
            }
          });
        });
        let countDate = 0;
        let countDateTime = 0;
        let index = 0;
        for (const e of this.detail) {
          e.validation = false;
          if (e.field_type_id == 2) {
            this.checkboxIndexList.push(index);
          } else if (e.field_type_id == 5) {
            e.indexDateTime = countDateTime;
            countDateTime += 1;
          } else if (e.field_type_id == 7) {
            e.indexDate = countDate;
            countDate += 1;
          } else if (e.field_type_id == 15) {
            await this.changeAddress(
              e.value,
              e,
              e.field_profile_type_id,
              false
            );
          }
          index++;
        }
        // this.detail.forEach(async (e, index) => {});

        this.isLoading = haveSelectBranch ? true : false;
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      this.branchList = this.$store.state.coupon.branchList.detail;
      field.options = [];
      this.branchList.forEach((e) => {
        e.branch.forEach((b) => {
          if (b.is_check) {
            field.options.push({ text: b.name, value: b.name });
          }
        });
      });
      this.isLoading = false;
    },
    updateImageList(list) {
      this.detail[list.index].value = list.value;
    },
    deleteImgList(index) {
      this.detail[index].value = "";
    },
    triggerCheckbox() {
      this.checkboxIndexList.forEach((e) => {
        this.detail[e].value = this.detail[e].value.split(",");
      });
    },
    async submitCampaign(status) {
      this.submitTriger = true;
      let checkValidation = this.customValidation();
      this.$forceUpdate();
      if (checkValidation) {
        return;
      } else {
        let original = JSON.parse(this.detailOriginal);
        this.detail.forEach((e) => {
          original.forEach((o) => {
            if (e.field_id == o.field_id) {
              if (e.field_type_id == 2) {
                if (e.value.length == o.value.length) {
                  let check = false;
                  e.value.forEach((v) => {
                    if (o.value.findIndex((d) => d == v) == -1) {
                      check = true;
                    }
                  });
                  e.is_update = check ? 1 : 0;
                } else {
                  e.is_update = 1;
                }
              } else {
                e.is_update = e.value == o.value ? 0 : 1;
              }
            }
          });
        });
        this.detail.forEach((e) => {
          if (e.name == "โทร") {
            this.form.telephone = e.value;
          }
        });

        let n = 0;
        let obj = [...this.detail];
        for (const x of obj) {
          if (typeof x.value == "object") {
            let sp = obj.splice(n, 1);
            let value = sp[0].value.filter((el) => el);
            if (value.length > 0) {
              for (const a of value) {
                const { id } = sp[0].field_choices.find(
                  (choice) => choice.name == a
                );

                obj.push({
                  ...x,
                  id: sp[0].id,
                  value: a,
                  target_id: id,
                  required: sp[0].required,
                });
              }
            }
          }
          n++;
        }

        let body = {
          is_accept: status,
          user_id: this.form.user_id,
          point: this.form.point,
          note: this.form.note,
          telephone: this.form.telephone,
          message: this.form.message,
          user_guid: this.form.user_guid,
          campaign_id: this.$route.query.campaignID,
          status: this.statusCampaign,
          user_fill: obj,
        };

        this.$bus.$emit("showLoading");
        await this.$store.dispatch("updateCampaign", body);
        let data = this.$store.state.form.respUpdateCampaign;
        if (data.result == 1) {
          this.successAlert().then(() => {
            this.$router.push(`/form/detail/${this.campaignId}?edit=1`);
          });
        } else {
          this.errorAlert(data.message);
        }
        this.$bus.$emit("hideLoading");
      }
    },
    customValidation() {
      let error = false;
      this.detail.forEach((d) => {
        if (d.required) {
          if (d.field_type_id == 1) {
            if (d.field_profile_type_id) {
              d.validation = d.value.length ? false : true;
            } else {
              if (d.field_conditions.length > 0)
                if (d.field_conditions[0].value == "text") {
                  if (d.field_conditions[1].value) {
                    d.validation =
                      d.value.length <= parseInt(d.field_conditions[1].value)
                        ? false
                        : true;
                  } else {
                    d.validation = d.value.length ? false : true;
                  }
                } else {
                  if (
                    d.field_conditions[2].value ||
                    d.field_conditions[3].value
                  ) {
                    if (d.field_conditions[2].value) {
                      d.validation = parseInt(d.value) * 0 == 0 ? false : true;
                    }
                    if (d.field_conditions[3].value) {
                      d.validation =
                        parseInt(d.value) * 0 == 0
                          ? d.validation || false
                          : true;
                    }
                  } else {
                    d.validation = parseInt(d.value) * 0 == 0 ? false : true;
                  }
                }
            }
          } else if (d.field_type_id == 2) {
            d.validation = d.value.length > 0 ? false : true;
          } else if (
            (d.field_type_id >= 3 && d.field_type_id <= 8) ||
            d.field_type_id == 12
          ) {
            d.validation = d.value.length > 0 ? false : true;
          } else if (d.field_type_id == 9) {
            d.validation = false;
          }
        }
      });
      error = this.detail.findIndex((d) => d.validation) == -1 ? false : true;
      return error;
    },
    openMessageModal() {
      this.modalMessageShow = true;
    },
    cancelMessageModel() {
      this.form.message = "";
      this.modalMessageShow = false;
    },
    submitMessageModel() {
      this.modalMessageShow = false;
      this.submitCampaign(0);
    },
    checkIsProfile(val) {
      const findField = this.fields.find(
        (el) => el.field_profile_type_id == val
      );
      return findField ? findField.key : false;
    },
    async changeAddress(val, field, id, set = true) {
      if (field) {
        let payload = field.field_choices.find((el) => el.name == val);

        let $keyset = id == 18 ? 1 : 2;

        if (payload) {
          let $set = this.detail.findIndex(
            (el) => el.field_id == field.field_dependent
          );
          if (set) {
            if (id == "18") {
              let $set1 = this.detail.findIndex(
                (el) => el.field_profile_type_id == 16
              );
              let $zip = this.detail.findIndex(
                (el) => el.field_profile_type_id == 19
              );

              this.detail[$set1].field_choices = [];
              this.detail[$set1].value = "";
              this.detail[$set].value = "";
              this.detail[$zip].value = "";
              // this.reqForm.profile_fields.zip_code = "";
            }
            if (id == "17") {
              this.detail[$set].value = "";
              // this.reqForm.profile_fields.zip_code = "";
            }
            if (id == "16") {
              this.detail[$set].value = payload.zip_code;
            }
          }
          const result = await this.axios(
            `/setting/Getsubaddress/${payload.id}/${$keyset}`
          );

          this.$nextTick(() => {
            this.detail[$set].field_choices =
              result.data.detail[$keyset == 1 ? "district" : "subdistrict"];
          });
          return result.data.detail[$keyset == 1 ? "district" : "subdistrict"];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-custom > textarea:focus {
  border-color: var(--primary-color);
}

.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
::v-deep .vdatetime-input {
  border: none;
  width: 100%;
}
.approve-btn {
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.approve-btn:hover {
  color: var(--primary-color);
  background-color: #fff;
}
.cancel-btn {
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.cancel-btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

label {
  // word-break: break-all;
}
</style>

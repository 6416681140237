<template>
  <div>
    <label
      >{{ field.name }}
      <span class="text-danger" v-if="field.required">*</span></label
    >
    <b-form-input
      :class="['input-custom', { error: field.validation }]"
      :type="
        field.field_conditions[0]
          ? field.field_conditions[0].value == 'text'
            ? 'text'
            : 'number'
          : 'text'
      "
      :disabled="statusCampaign != 0 && field.field_profile_type_id != 0"
      v-model="field.value"
    ></b-form-input>
    <div v-if="field.validation">
      <span class="text-error">กรุณาใส่ข้อมูลให้ถูกต้อง</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldTextInput",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>

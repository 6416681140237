<template>
  <div>
    <label
      >{{ field.name }}
      <span class="text-danger" v-if="field.required">*</span></label
    ><br />
    <textarea
      :class="[
        'form-control input-custom',
        { error: field.validation },
      ]"
      rows="3"
      :name="field.name"
      v-model="field.value"
      :disabled="statusCampaign != 0 && field.field_profile_type_id != 0"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "FieldTextAreaInput",
  props: {
    field: {
      required: true,
      type: Object,
    },
    statusCampaign: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-custom.error {
  border-color: red !important;
}
.text-error {
  color: #ff0000;
  font-size: 14px !important;
  font-weight: normal !important;
}
</style>
